.marquee {
  height: 50px;
  width: 100vw;
  overflow: hidden;
  position: relative;
  padding-top: 1vmin;
}

/* .marquee div {
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  color: white;
  font-family: "Bungee";
  font-size: 2vmin;
  animation: marquee 20s linear infinite;
} */

.marquee span {
  float: left;
  width: 50%;
}

@keyframes marquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
